import React, { useEffect, useState } from "react";
import { getUserDetail, calculatePDO } from "../../controllers/firebase";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CircularProgress
} from "@material-ui/core";
import Styled from "styled-components";
import format from "date-fns/format";
import { getWeekInfo } from "../../controllers/firebase";
import { exportExcel } from "../../controllers/excel";
import Button from "@material-ui/core/Button";

function getPips(vote, result, endRate, startRate) {
  let absPips = Math.round(Math.abs(endRate - startRate) * 10000);
  if (result === vote) {
    return absPips;
  }
  return 0 - absPips;
}

const UserDetails = props => {
  let [user, setUser] = useState(null);
  let [votes, setVotes] = useState(null);

  useEffect(() => {
    getUserDetail(props.match.params.uid).then(userDetail => {
      setUser(userDetail.user);
      userDetail.votes.forEach((vote, index) => {
        //fetch week data for each vote
        getWeekInfo(vote.weekKey).then(weekInfo => {
          vote.weekInfo = weekInfo;
          userDetail.votes[index] = vote;
          setVotes([...userDetail.votes]);
        });
      });
      setVotes(userDetail.votes);
    });
  }, []);
  const [loading, setLoading] = useState(false);
  function exportConsensusData(name) {
    setLoading(true);
    let sheet = [];
    let header = [
      "User",
      "User Email",
      "User Id",
      "Prediction Date",
      "EUR/USD Week Open Level",
      "EUR/USD Week Close Level",
      "User Prediction",
      "Prediction Correct?",
      "Pips Profit or Loss"
    ];
    let body = votes.map(i => [
      user.name,
      user.email || "-----------------",
      user.uid,
      format(new Date(i.weekInfo.startedAt), "MMM. DD, YYYY"),
      i.weekInfo.startRate,
      i.weekInfo.endRate,
      i.vote,
      i.weekInfo.result === i.vote ? "Yes" : "No",
      getPips(
        i.vote,
        i.weekInfo.result,
        i.weekInfo.endRate,
        i.weekInfo.startRate
      )
    ]);
    sheet.push(header);
    sheet.push(...body);
    exportExcel(sheet, name.replace(" ", "") + ".xlsx");
    setLoading(false);
  }

  function getVoteRows() {
    return votes.map(vote => {
      if (vote.weekInfo) {
        let startRate = parseFloat(vote.weekInfo.startRate).toFixed(4);
        let endRate = parseFloat(vote.weekInfo.endRate).toFixed(4);
        return (
          <tr>
            <td>
              {format(new Date(vote.weekInfo.startedAt), "MMM. DD, YYYY")}
            </td>
            <td style={{ textAlign: "right" }}>{startRate}</td>
            <td style={{ textAlign: "right" }}>{endRate}</td>
            <td style={{ textAlign: "right" }}>{vote.vote}</td>
            <td style={{ textAlign: "right" }}>
              {vote.weekInfo.result === vote.vote ? "Yes" : "No"}
            </td>
            <td style={{ textAlign: "right" }}>
              {getPips(vote.vote, vote.weekInfo.result, startRate, endRate)}
            </td>
          </tr>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              right: 0,
              left: 0,
              top: 0,
              bottom: 0,
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "240px"
            }}
          >
            <CircularProgress />
          </div>
        );
      }
    });
  }
  function getVotes() {
    if (votes.length === 0) {
      return (
        <Typography
          variant="caption"
          style={{ textAlign: "center", fontSize: "16px", marginTop: "40px" }}
        >
          There are no votes for this user
        </Typography>
      );
    }

    return (
      <CardStyled>
        <Table>
          <thead>
            <tr>
              <th>Week</th>
              <th style={{ textAlign: "right" }}>Open Level</th>
              <th style={{ textAlign: "right" }}>Close Level</th>
              <th style={{ textAlign: "right" }}>Vote</th>
              <th style={{ textAlign: "right" }}>Correct</th>
              <th style={{ textAlign: "right" }}>Pips</th>
            </tr>
          </thead>
          <tbody>{getVoteRows()}</tbody>
        </Table>
      </CardStyled>
    );
  }
  function getUserCard() {
    return (
      <Card
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardMediaStyled image={user.photo_url} title="User Profile Photo" />
          <CardContentStyled>
            <Typography gutterBottom variant="h5" component="h5">
              {user.name}
            </Typography>
            <Typography gutterBottom variant="caption">
              {user.email}
            </Typography>
          </CardContentStyled>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto 10px",
            alignContent: "center"
          }}
        >
          <div
            style={{
              color: "green",
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "bold"
            }}
          >
            {`${(user.accuracy * 100).toFixed(2)} %`}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "12px"
                }}
              >
                accurate
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  if (user) {
    return (
      <div>
        <div>{getUserCard()}</div>
        <div style={{ display: "flex", "justify-content": "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px 0" }}
            disabled={loading}
            onClick={() => exportConsensusData(user.name)}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  position: "absolute",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              "Export User Votes"
            )}
          </Button>
        </div>
        <div>{votes && getVotes()}</div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "240px"
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const CardMediaStyled = Styled(CardMedia)`
&&{
    width:60px;
    height:60px;
    margin-left:20px;
    align-self:center;
    border-radius:100%;
}   
`;

const CardContentStyled = Styled(CardContent)`
&&{
    align-self:center;
}   
`;

const Table = Styled.table`
  padding: 10px;
  width: 100%;
  min-width: 700px;
  text-align: center;
  border-collapse: collapse;
  overflow: scroll;
  table-layout: fixed;
  & th, td {
    padding: 20px;
    text-align: left;
  }
  & tr:nth-child(2n) {
    background-color: #f4f7fa;
  }
  & th {
    font-weight: 800;
    color: rgba(0,0,0,0.6);
    background-color: #f4f7fa;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

const CardStyled = Styled(Card)`
    &&{
        margin-top:20px;
    }
`;
export default UserDetails;
