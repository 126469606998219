import Styled from "styled-components";

export const CenterScreen = Styled.div`
    color:{props=>props.theme.secondary};
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:100vh;
    margin:auto;
`;
export const StyledForm = Styled.form`
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-evenly;
`;

export const Container = Styled.form`
  
`;
export const Horizontal = Styled.div`
  display:flex;
  flex-direction:row;
`;

export const Title = Styled.h3`
  color: rgba(0,0,0,1);
  font-size: 26px;
  margin: 20px 0;
  font-weight: 400;
`;

export const SubTitle = Styled.h4`
  color: rgba(0,0,0,0.8);
  font-size: 22px;
  margin: 16px 0 10px 0;
  font-weight: 400;
`;

export const Line = Styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  height: 1px;
  margin: 20px 0;
`;
