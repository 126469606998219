import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Styled from "styled-components";
import {
  getConsensusData,
  getAllConsensusData,
  updateConsensusData
} from "../controllers/firebase";
import format from "date-fns/format";
// import Loader from "./Generics/Loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Title } from "../styles/styled";
import Button from "@material-ui/core/Button";
import { exportExcel } from "../controllers/excel";
import EditIcon from "@material-ui/icons/Edit";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const Home = props => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const managedPipsRef = React.useRef();

  const handleClickOpen = i => {
    setSelectedKey(i.key);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function submit() {
    setLoading(true);
    handleClose();
    console.log("pips", managedPipsRef.current.value, selectedKey);
    updateConsensusData(selectedKey, managedPipsRef.current.value).then(res => {
      setLoading(false);
    });
  }

  function exportConsensusData() {
    setLoading(true);
    getAllConsensusData().then(data => {
      let sheet = [];
      let header = [
        "Date",
        "Open Rate",
        "Close Rate",
        "Total Votes",
        "Below Votes",
        "Above Votes",
        "Result",
        "Consensus",
        "Consensus Correct?",
        "Pips Profit or Loss",
        "Pips with Stops and Limits"
      ];
      let body = data.map(i => [
        format(new Date(i.startedAt), "MMM. DD, YYYY"),
        i.startRate,
        i.endRate || "----",
        i.totalVoteCount,
        i.belowVoteCount,
        i.aboveVoteCount,
        i.result || "----",
        i.consensus,
        i.result === i.consensus ? "YES" : "NO",
        i.pips || "----",
        i.managedPips || "----"
      ]);
      let length = data.filter(i => i.result).length;
      let accuracy =
        (data.reduce((prev, curr) => {
          if (curr.result === curr.consensus) return prev + 1;
          return prev;
        }, 0) /
          length) *
        100;
      let averagePips =
        data.reduce((prev, curr) => {
          if (!curr.result) return prev;
          return prev + curr.pips;
        }, 0) / length;

      sheet.push([]);
      sheet.push([]);
      sheet.push(["Consensus Weekly Accuracy", "", accuracy]);
      sheet.push(["Average weekly pips profit", "", averagePips]);
      sheet.push([]);
      sheet.push([]);
      sheet.push(header);
      sheet.push(...body);
      exportExcel(sheet);
      setLoading(false);
    });
  }
  return loading ? (
    <LoaderDiv>
      <CircularProgress />
    </LoaderDiv>
  ) : (
    <div>
      <Title>
        ConsensusFX Performance
        <Button
          variant="contained"
          color="primary"
          style={{ float: "right" }}
          disabled={loading}
          onClick={() => exportConsensusData()}
        >
          {loading ? "Loading..." : "Export Consensus"}
        </Button>
      </Title>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Managed Pip</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="managedPips"
            label="managedPips"
            type="number"
            fullWidth
            inputRef={managedPipsRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <DataTable handleClickOpen={handleClickOpen} handleClose={handleClose} />
    </div>
  );
};

export default Home;

const LoaderDiv = Styled.div`
  height: 405px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f7fa;
`;

const ScrollView = Styled.div`
  overflow: scroll;
`;

const Table = Styled.table`
  padding: 10px;
  width: 100%;
  min-width: 700px;
  text-align: center;
  border-collapse: collapse;
  overflow: scroll;
  table-layout: fixed;
  & th, td {
    padding: 20px;
    text-align: left;
  }
  & tr:nth-child(2n) {
    background-color: #f4f7fa;
  }
  & th {
    font-weight: 800;
    color: rgba(0,0,0,0.6);
    background-color: #f4f7fa;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

function DataTable(props) {
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAllConsensusData().then(data => {
      setData(data);
      setLoading(false);
    });
  }, []);

  return (
    <Paper style={{ borderRadius: 10, overflow: "hidden" }}>
      {/* <PaperTitle>Performance</PaperTitle> */}
      <ScrollView>
        {loading ? (
          <LoaderDiv>
            <CircularProgress />
          </LoaderDiv>
        ) : data.length ? (
          <Table>
            <thead>
              <tr>
                <th>Week</th>
                <th style={{ textAlign: "right" }}>Open Level</th>
                <th style={{ textAlign: "right" }}>Close Level</th>
                <th style={{ textAlign: "right" }}>Total Votes</th>
                <th style={{ textAlign: "right" }}>Down Votes</th>
                <th style={{ textAlign: "right" }}>Up Votes</th>
                <th style={{ textAlign: "right" }}>Result</th>
                <th style={{ textAlign: "right", wordBreak: "break-all" }}>
                  Consensus
                </th>
                <th style={{ textAlign: "right" }}>Correct</th>
                <th style={{ textAlign: "right" }}>Pips</th>
                <th style={{ textAlign: "right" }}>Managed Pips</th>
              </tr>
            </thead>
            <tbody>
              {data.map(i => (
                <tr key={i.startedAt}>
                  <td>{format(new Date(i.startedAt), "MMM. DD, YYYY")}</td>
                  <td style={{ textAlign: "right" }}>{i.startRate}</td>
                  <td style={{ textAlign: "right" }}>{i.endRate || "----"}</td>
                  <td style={{ textAlign: "right" }}>{i.totalVoteCount}</td>
                  <td style={{ textAlign: "right" }}>{i.belowVoteCount}</td>
                  <td style={{ textAlign: "right" }}>{i.aboveVoteCount}</td>
                  <td style={{ textAlign: "right" }}>{i.result || "----"}</td>
                  <td style={{ textAlign: "right" }}>{i.consensus}</td>
                  <td style={{ textAlign: "right" }}>
                    {i.consensus === i.result ? "YES" : "NO"}
                  </td>
                  <td style={{ textAlign: "right" }}>{i.pips || "----"}</td>
                  <td style={{ textAlign: "right" }}>
                    {i.managedPips || "0"}{" "}
                    <EditIcon
                      style={{
                        cursor: "pointer",
                        color: "grey",
                        fontSize: "1.2em",
                        marginBottom: "-.125em"
                      }}
                      onClick={() => props.handleClickOpen(i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: "center", padding: 40 }}>No data !</div>
        )}
      </ScrollView>
    </Paper>
  );
}
