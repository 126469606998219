import React from "react";
import {
  Drawer,
  IconButton,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Styled from "styled-components";

export default function({
  drawer,
  toggle,
  routes,
  setTitle,
  active,
  ...props
}) {
  const sideItems = (
    <List>
      {routes.map(({ route, name, logout, icon, sidebar }) => {
        if (!sidebar) return;
        const route_abs = "/dashboard/" + route;
        return (
          <StyledListItem
            button
            key={name}
            active={active === route}
            onClick={() => {
              props.history.push(route_abs);
            }}
            {...props}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
              className="waves-effect"
              to={route_abs}
              onClick={() => setTitle(name)}
            >
              {name}
            </ListItemText>
            <Divider />
          </StyledListItem>
        );
      })}
    </List>
  );

  return (
    <Drawer
      open={drawer}
      onClose={_ => toggle(false)}
      variant="permanent"
      anchor="left"
    >
      <IconContainer theme={props.theme}>
        <Logo>ConsensusFX</Logo>
        {/* <IconButton onClick={_ => toggle(false)}>
          <StyledCross primaryColor={props.theme.palette.primary.main} />
        </IconButton> */}
      </IconContainer>
      <div
        tabIndex={0}
        role="button"
        onClick={_ => toggle(false)}
        onKeyDown={_ => toggle(false)}
      >
        {sideItems}
      </div>
    </Drawer>
  );
}

const IconContainer = Styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content:flex-end;
    border-bottom:${props => "1px solid " + props.theme.palette.primary.main};
    height:${props => props.theme.spacing.unit * 8 + "px"};
`;

const Logo = Styled.div`
  flex:1;
  margin:auto;
  font-size:24px;
  font-weight:bold;
  text-align:center;
`;

// const StyledCross = Styled(Close)`
// &&{
//   color:${props => props.primaryColor}}
// `;

const StyledListItem = Styled(ListItem)`
  &&{
    width:${props => props.width + "px"};
    span{
      color:${props => (props.active ? props.theme.palette.primary.main : null)}
    };
    div{
    color:${props => (props.active ? props.theme.palette.primary.main : null)}
    }
  }
`;
