import React, { useEffect, useState } from "react";
import { Title, SubTitle, Line } from "../styles/styled";
import Styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  getNotificationOptions,
  updateEndNotification,
  updateStartNotification,
  updateResultNotification,
  updateReminderNotification
} from "../controllers/firebase";
import { CircularProgress } from "@material-ui/core";

const Form = () => (
  <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
    <div>
      <SubTitle> Vote Start Notification </SubTitle>
      <span style={{ fontStyle: "italic", opacity: 0.5 }}>
        Note: To add current rate in the notification, add{" "}
        <span style={{ fontWeight: 700 }}>"$&#123;rate&#125;"</span>
      </span>
    </div>
    <div>
      <TextField
        id="outlined-title"
        label="Title"
        style={{ backgroundColor: "white", width: "100%" }}
        // value={this.state.name}
        // onChange={this.handleChange('name')}
        margin="normal"
        variant="outlined"
      />
      <TextField
        id="outlined-body"
        label="Body"
        style={{ backgroundColor: "white", width: "100%" }}
        // value={this.state.name}
        // onChange={this.handleChange('name')}
        margin="normal"
        variant="outlined"
        multiline
        rows="3"
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" style={{ marginTop: 10 }}>
          Update
        </Button>
      </div>
    </div>
  </div>
);

export default function Notifications(props) {
  let [notifications, setNotifications] = useState();
  useEffect(() => {
    getNotificationOptions().then(notifications => {
      setNotifications(notifications);
    });
  }, []);

  const updateNotificationEnd = () => {
    updateEndNotification(notifications.end).then(resp => {
      alert("notification updated");
      //todo:showsnackbar
    });
  };
  const updateNotificationStart = () => {
    updateStartNotification(notifications.start).then(resp => {
      alert("notification updated");
      //todo:showsnackbar
    });
  };
  const updateNotificationReminder = () => {
    updateReminderNotification(notifications.reminder).then(resp => {
      //todo:showsnackbar
      alert("notification updated");
    });
  };
  const updateNotificationResult = () => {
    updateResultNotification(notifications.result).then(resp => {
      alert("notification updated");
      //todo:showsnackbar
    });
  };
  function updateStart(e) {
    setNotifications({
      ...notifications,
      start: {
        ...notifications.start,
        [e.target.name]: e.target.value
      }
    });
  }
  function updateEnd(e) {
    setNotifications({
      ...notifications,
      end: {
        ...notifications.end,
        [e.target.name]: e.target.value
      }
    });
  }
  function updateReminder(e) {
    setNotifications({
      ...notifications,
      reminder: {
        ...notifications.reminder,
        [e.target.name]: e.target.value
      }
    });
  }
  function updateResult(e) {
    setNotifications({
      ...notifications,
      result: {
        ...notifications.result,
        [e.target.name]: e.target.value
      }
    });
  }
  return (
    <>
      {notifications ? (
        <>
          <Title>Configure Notifications</Title>
          <Line />
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <SubTitle>Vote Start Notification</SubTitle>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: "100%" }}>
                <span style={{ fontStyle: "italic", opacity: 0.5 }}>
                  Note: To add current rate in the notification, add{" "}
                  <span style={{ fontWeight: 700 }}>"$&#123;rate&#125;"</span>
                </span>
                <TextField
                  id="outlined-title"
                  label="Title"
                  name="title"
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={notifications.start ? notifications.start.title : ""}
                  onChange={updateStart}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="outlined-body"
                  label="Body"
                  name="body"
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={notifications.start ? notifications.start.body : ""}
                  onChange={updateStart}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows="3"
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={updateNotificationStart}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <SubTitle>Vote Reminder Notification</SubTitle>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: "100%" }}>
                <span style={{ fontStyle: "italic", opacity: 0.5 }}>
                  Note: To add current rate in the notification, add{" "}
                  <span style={{ fontWeight: 700 }}>"$&#123;rate&#125;"</span>
                </span>
                <TextField
                  id="outlined-title"
                  label="Title"
                  name="title"
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={
                    notifications.reminder ? notifications.reminder.title : ""
                  }
                  onChange={updateReminder}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="outlined-body"
                  label="Body"
                  name="body"
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={
                    notifications.reminder ? notifications.reminder.body : ""
                  }
                  onChange={updateReminder}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows="3"
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={updateNotificationReminder}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <SubTitle>Result Notification</SubTitle>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: "100%" }}>
                <span style={{ fontStyle: "italic", opacity: 0.5 }}>
                  Note: To add result in the notification, add{" "}
                  <span style={{ fontWeight: 700 }}>"$&#123;result&#125;"</span>
                </span>
                <TextField
                  id="outlined-title"
                  label="Title"
                  name="title"
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={notifications.result ? notifications.result.title : ""}
                  onChange={updateResult}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="outlined-body"
                  label="Body"
                  style={{ backgroundColor: "white", width: "100%" }}
                  value={notifications.result ? notifications.result.body : ""}
                  onChange={updateResult}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows="3"
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={updateNotificationResult}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "240px"
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
}
