import React, { useState, useEffect } from "react";
import Select from "./Generics/Select";
import TimeInput from "material-ui-time-picker";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  fetchVotingPeriod,
  updateVotingParameters
} from "../controllers/firebase";
import { updateStartCron } from "../controllers/easyCron";

import { Title, SubTitle, Line } from "../styles/styled";
import { CircularProgress } from "@material-ui/core";

export default function Timer(props) {
  useEffect(() => {
    setLoading(true);
    //fetch previously set time and day for voting period
    fetchVotingPeriod().then(value => {
      setLoading(false);
      let beginTime = new Date();
      beginTime.setHours(value.start_time.hour);
      beginTime.setMinutes(
        value.start_time.minute == null ? 0 : value.start_time.minute
      );
      let resultTime = new Date();
      resultTime.setHours(value.result.hour);
      resultTime.setMinutes(
        value.result.minute == null ? 0 : value.result.minute
      );

      let endTime = new Date();
      endTime.setHours(value.end_time.hour);
      endTime.setMinutes(
        value.end_time.minute == null ? 0 : value.end_time.minute
      );

      let remindTime = new Date();
      remindTime.setHours(value.remind_time.hour);
      remindTime.setMinutes(
        value.remind_time.minute == null ? 0 : value.remind_time.minute
      );

      if (value.result.day) {
        setResultDay(value.result.day);
      }
      setResultTime(resultTime);

      if (value.start_time.day) {
        setDay(value.start_time.day);
      }
      setStartTime(beginTime);

      if (value.end_time.day) {
        setEndDay(value.start_time.day);
      }
      setEndTime(endTime);

      if (value.start_time.day) {
        setRemindDay(value.start_time.day);
      }
      setRemindTime(remindTime);
    });
  }, []);

  let [loading, setLoading] = useState(false);
  let [day, setDay] = useState(0);
  let [startTime, setStartTime] = useState(null);
  let [resultTime, setResultTime] = useState(null);
  let [resultDay, setResultDay] = useState(0);
  let [remindDay, setRemindDay] = useState(0);
  let [remindTime, setRemindTime] = useState(null);
  let [endDay, setEndDay] = useState(0);
  let [endTime, setEndTime] = useState(null);

  const updateParameters = () => {
    // var intInterval = parseInt(interval);
    // var intNotifyBefore = parseInt(notifyBefore);
    // call the easy cron api to register an event
    console.log("endTime", endTime);
    updateStartCron({
      startDay: day,
      startTime: startTime,
      endTime,
      endDay,
      resultDay,
      resultTime,
      remindTime,
      remindDay
    }).then(resp => {
      //show toast
    });
    updateVotingParameters({
      start_time: {
        hour: startTime.getHours(),
        minute: startTime.getMinutes(),
        day: day
      },
      end_time: {
        hour: endTime.getHours(),
        minute: endTime.getMinutes(),
        day: endDay
      },
      result: {
        hour: resultTime.getHours(),
        minute: resultTime.getMinutes(),
        day: resultDay
      },
      remind_time: {
        hour: remindTime.getHours(),
        minute: remindTime.getMinutes(),
        day: remindDay
      }
    }).then(resp => {
      alert("Updated");
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "240px"
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Title>Configure Voting Period</Title>
      <Line />
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <SubTitle>Voting Starts At: </SubTitle>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Select
            label="Day of Week"
            handleChange={setDay}
            options={[
              { name: "Sunday", value: 0 },
              { name: "Monday", value: 1 },
              { name: "Tuesday", value: 2 },
              { name: "Wednesday", value: 3 },
              { name: "Thursday", value: 4 },
              { name: "Friday", value: 5 },
              { name: "Saturday", value: 6 }
            ]}
            value={day}
          />
          <TimeInput
            mode="12h"
            value={startTime}
            onChange={setStartTime}
            inputComponent={props => (
              <TextField
                id="outlined-title"
                label="Time"
                style={{ backgroundColor: "white" }}
                margin="normal"
                variant="outlined"
                {...props}
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <SubTitle>Remind Users At:</SubTitle>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Select
            label="Day of Week"
            handleChange={setRemindDay}
            options={[
              { name: "Sunday", value: 0 },
              { name: "Monday", value: 1 },
              { name: "Tuesday", value: 2 },
              { name: "Wednesday", value: 3 },
              { name: "Thursday", value: 4 },
              { name: "Friday", value: 5 },
              { name: "Saturday", value: 6 }
            ]}
            value={remindDay}
          />
          <TimeInput
            mode="12h"
            value={remindTime}
            onChange={setRemindTime}
            inputComponent={props => (
              <TextField
                id="outlined-title"
                label="Time"
                style={{ backgroundColor: "white" }}
                margin="normal"
                variant="outlined"
                {...props}
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <SubTitle>Voting Ends At: </SubTitle>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Select
            label="Day of Week"
            handleChange={setEndDay}
            options={[
              { name: "Sunday", value: 0 },
              { name: "Monday", value: 1 },
              { name: "Tuesday", value: 2 },
              { name: "Wednesday", value: 3 },
              { name: "Thursday", value: 4 },
              { name: "Friday", value: 5 },
              { name: "Saturday", value: 6 }
            ]}
            value={endDay}
          />
          <TimeInput
            mode="12h"
            value={endTime}
            onChange={setEndTime}
            inputComponent={props => (
              <TextField
                id="outlined-title"
                label="Time"
                style={{ backgroundColor: "white" }}
                margin="normal"
                variant="outlined"
                {...props}
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
        <div>
          <SubTitle>Calculate Result At: </SubTitle>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Select
            label="Day of Week"
            handleChange={setResultDay}
            options={[
              { name: "Sunday", value: 0 },
              { name: "Monday", value: 1 },
              { name: "Tuesday", value: 2 },
              { name: "Wednesday", value: 3 },
              { name: "Thursday", value: 4 },
              { name: "Friday", value: 5 },
              { name: "Saturday", value: 6 }
            ]}
            value={resultDay}
          />
          <TimeInput
            mode="12h"
            value={resultTime}
            onChange={setResultTime}
            inputComponent={props => (
              <TextField
                id="outlined-title"
                label="Time"
                style={{ backgroundColor: "white" }}
                margin="normal"
                variant="outlined"
                {...props}
              />
            )}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 10 }}
          onClick={updateParameters}
        >
          Update
        </Button>
      </div>
    </>
  );
}
