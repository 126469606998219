import React, { useEffect, useState } from "react";
import { getUsers } from "../../controllers/firebase";
import { Button, Card, CircularProgress } from "@material-ui/core";
import Styled from "styled-components";

const Users = props => {
  let [users, setUsers] = useState(null);
  let [from, setFrom] = useState(null);
  useEffect(() => {
    getUsers(null, 500).then(users => {
      if (users.length !== 0) {
        setUsers(users);
        setFrom(users[users.length - 1].name);
      }
    });
  }, []);
  function loadMore() {
    if (users.length)
      getUsers(from, 500).then(olderUsers => {
        if (olderUsers.length !== 0) {
          setUsers([...users, ...olderUsers]);
          setFrom(olderUsers[olderUsers.length - 1].name);
        } else {
          alert("No more users");
        }
      });
  }

  function getUsersList() {
    return users.map(user => {
      return (
        <tr
          key={user.email}
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.history.push(`${props.match.path}/${user.uid}`);
          }}
        >
          <td width="10%" style={styles.centerText}>
            {users.indexOf(user) + 1}
          </td>
          <td width="30%" style={styles.centerText}>
            {user.name || "-"}
          </td>
          <td width="30%" style={styles.centerText}>
            {user.email}
          </td>
          <td width="5%" style={styles.centerText}>
            {user.totalVotes}
          </td>
          <td width="20%" style={styles.centerText}>
            {(user.accuracy * 100).toFixed(2)} %
          </td>
        </tr>
      );
    });
  }
  return (
    <div>
      {users ? (
        <Card>
          <Table>
            <thead>
              <tr>
                <th style={styles.centerText}>SN</th>
                <th style={styles.centerText}>Name</th>
                <th style={styles.centerText}>Email</th>
                <th style={styles.centerText}>Total Votes</th>
                <th style={styles.centerText}>Accuracy</th>
              </tr>
            </thead>
            <tbody>{getUsersList()}</tbody>
          </Table>
        </Card>
      ) : (
        <div
          style={{
            display: "flex",
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "240px"
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {users && (
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 10 }}
            onClick={loadMore}
          >
            Load more
          </Button>
        )}
      </div>
    </div>
  );
};

const Table = Styled.table`
  padding: 10px;
  width: 100%;
  min-width: 700px;
  text-align: center;
  border-collapse: collapse;
  overflow: scroll;
  table-layout: fixed;
  & th, td {
    padding: 20px;
    text-align: left;
  }
  & tr:nth-child(2n) {
    background-color: #f4f7fa;
  }
  & th {
    font-weight: 800;
    color: rgba(0,0,0,0.6);
    background-color: #f4f7fa;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

var styles = {
  centerText: { textAlign: "center" }
};
export default Users;
