import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Styled from "styled-components";
import DashboardIcon from "mdi-react/ViewDashboardIcon";
import BellRingIcon from "mdi-react/BellRingIcon";
import TimerIcon from "mdi-react/TimerIcon";
import PeopleIcon from "mdi-react/PeopleIcon";
import ChartIcon from "mdi-react/ChartArcIcon";
import UserRoutes from "../components/Users/";
import { withTheme } from "@material-ui/core/styles";
import Drawer from "../components/Generics/drawer";
import AppBar from "../components/Generics/appbar";
import Home from "../components/Home";
import Notifications from "../components/Notifications";
import Timer from "../components/Timer";
import PDO from "../components/PDO";

const routes = [
  {
    route: "",
    component: Home,
    name: "Dashboard",
    icon: <DashboardIcon />,
    sidebar: true,
    exact: true
  },
  {
    route: "notifications",
    component: Notifications,
    name: "Notifications",
    icon: <BellRingIcon />,
    sidebar: true,
    exact: true
  },
  {
    route: "timer",
    component: Timer,
    name: "Voting Period",
    icon: <TimerIcon />,
    sidebar: true,
    exact: true
  },
  {
    route: "users/",
    component: UserRoutes,
    name: "Users",
    icon: <PeopleIcon />,
    sidebar: true,
    exact: false
  },
  {
    route: "pdo",
    component: PDO,
    name: "PDO",
    icon: <ChartIcon />,
    sidebar: true,
    exact: false
  }
];

const drawerWidth = 240;
const Dashboard = props => {
  const [title, setTitle] = useState("Dashboard");
  const [drawer, toggleDrawer] = useState(true);
  const [active, setActive] = useState("");

  return (
    <>
      <Drawer
        active={active}
        width={drawerWidth}
        toggle={toggleDrawer}
        drawer={drawer}
        routes={routes}
        setTitle={setTitle}
        {...props}
      />
      <Main theme={props.theme}>
        <AppBar toggle={toggleDrawer} title={title} />
        <Routes>
          <Switch>
            {routes.map(route => (
              <Route
                key={route.name}
                path={"/dashboard/" + route.route}
                component={props => {
                  setActive(route.route);
                  let Component = route.component;
                  return <Component {...props} />;
                }}
                exact={route.exact}
              />
            ))}
          </Switch>
        </Routes>
      </Main>
    </>
  );
};

export default withTheme()(Dashboard);

const Routes = Styled.div`
  padding:15px;
`;

const Main = Styled.div`
  min-height:100%;
  /* margin-top:${props => props.theme.spacing.unit * 7 + 1 + "px"} */
  margin-left:${drawerWidth + "px"};
  background-color: hsla(200, 20%, 95%, 1);
`;
