import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Loader from "./Generics/Loader";
import { auth } from "firebase";

// alternative = (string) route to be redirected to if the authentication go as expected
// shouldFail = (bool) to represent if failed authentication is expected

function renderProps(authenticated, shouldFail, altPath, Component, props) {
  if (authenticated === null) {
    return <Loader />;
  } else if ((authenticated && !shouldFail) || (!authenticated && shouldFail)) {
    return <Component {...props} />;
  } else {
    return <Redirect to={altPath} />;
  }
}

export default function AuthenticatedRoute({
  shouldFail,
  altPath,
  component: Component,
  ...rest
}) {
  const [authenticated, setAuthenticated] = useState(null);
  auth().onAuthStateChanged(async user => {
    if (user) {
      let data = await user.getIdTokenResult();
      if (data.claims.admin) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    } else setAuthenticated(false);
  });
  return (
    <Route
      {...rest}
      render={props =>
        renderProps(authenticated, shouldFail, altPath, Component, props)
      }
    />
  );
}

export const PrivateRoute = props => (
  <AuthenticatedRoute altPath="/login" shouldFail={false} {...props} />
);
export const LoginRoute = props => (
  <AuthenticatedRoute altPath="/dashboard" shouldFail {...props} />
);
