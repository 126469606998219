import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CenterScreen } from "../../styles/styled";

export default function Loader() {
  return (
    <CenterScreen>
      <CircularProgress />
    </CenterScreen>
  );
}
