import { LoginRoute, PrivateRoute } from "./components/AuthenticatedRoute";
import { Switch, Redirect, Router } from "react-router-dom";
import Dashboard from "./pages/Dashboard.jsx";
import React, { Component } from "react";
import Login from "./pages/Login.jsx";
import createHistory from "history/createBrowserHistory";
import { initFirebase, auth } from "./controllers/firebase";
import { MuiThemeProvider, Snackbar } from "@material-ui/core";
import theme from "./styles/theme";
import { BaseStyles } from "./styles/styled";
import "./index.css";

const history = createHistory();

class App extends Component {
  state = {
    snack: true,
    message: ""
  };
  componentWillMount() {
    initFirebase();
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <LoginRoute path="/login" component={Login} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Redirect to="/dashboard" />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}
export default App;
