import { createMuiTheme } from "@material-ui/core/styles";
import { blue, pink, red, grey } from "@material-ui/core/colors";

export default createMuiTheme({
  palette: {
    primary: { main: "#007BEF" },
    secondary: { main: "#FEB916" },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // //example
    // headline: {
    //   fontSize: "1.5rem",
    //   fontWeight: 400,
    //   fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    //   lineHeight: "1.35417em",
    //   color: "rgba(0, 0, 0, 0.87)"
    // }
    subheading: {
      fontSize: 18,
      fontWeight: 500
    }
  }
});
