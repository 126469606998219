import firebase from "firebase";
import { start } from "pretty-error";

const config = {
  apiKey: "AIzaSyC2TDvR7HM0nMAYBLSz9yX497_LSDoAfR8",
  authDomain: "consensusfx-23e72.firebaseapp.com",
  databaseURL: "https://consensusfx-23e72.firebaseio.com",
  projectId: "consensusfx-23e72",
  storageBucket: "consensusfx-23e72.appspot.com",
  messagingSenderId: "229709536620",
  appId: "1:229709536620:web:d27e6b79733cfd6f49a749"
};
let authenticated = null;
export const getAuthenticated = () => {
  return authenticated;
};
let init = false;
export function initFirebase() {
  if (!init) {
    try {
      firebase.initializeApp(config);
    } catch (e) {
      console.log("Firebase app already exists");
    }
    auth().onAuthStateChanged(
      async user => {
        authenticated = false;
        if (user) {
          let data = await user.getIdTokenResult();
          if (data.claims.admin) authenticated = true;
        }
      },
      err => {
        console.log("error in auth", err);
      }
    );
    init = true;
  }
}

export const auth = firebase.auth;

//authentication functions
export async function loginWithEmailPassword(email, password) {
  let user = await firebase.auth().signInWithEmailAndPassword(email, password);
  let data = await user.user.getIdTokenResult();
  if (data.claims.admin) {
    return user;
  }
  logout();
  throw { message: "The user is not authorized to access this page" };
}

export function isAuthenticated() {}

export async function logout() {
  await firebase.auth().signOut();
}

export async function getConsensusData() {
  let res = await firebase
    .firestore()
    .collection("weeks")
    .orderBy("startedAt", "desc")
    .limit(6)
    .get();

  return res.docs.map(i => i.data());
}

export async function getAllConsensusData() {
  let res = await firebase
    .firestore()
    .collection("weeks")
    // .where("published", "==", true)
    .orderBy("startedAt", "desc")
    .get();

  return res.docs.map(i => ({ ...i.data(), key: i.id }));
}

export async function updateConsensusData(key, value) {
  let res = await firebase
    .firestore()
    .collection("weeks")
    .doc(key)
    .update({ managedPips: parseInt(value) });
  return res;
}

export async function getNotificationOptions() {
  let res = await firebase
    .database()
    .ref("notifications")
    .once("value");
  return res.val();
}
export async function fetchVotingPeriod() {
  let res = await firebase
    .database()
    .ref("parameters")
    .once("value");
  return res.val();
}

export async function updateVotingParameters(params) {
  return firebase
    .database()
    .ref("parameters")
    .set(params)
    .then(resp => {
      //showToast
    })
    .catch(e => {
      console.log("there was a problem updating voting parameters", e);
    });
}
export async function updateEndNotification(end) {
  // end={
  //   title:string,
  //   body:string
  // }
  return firebase
    .database()
    .ref("notifications")
    .child("end")
    .set(end)
    .then(resp => {
      //showToast
    })
    .catch(e => {
      console.log("there was a problem updating notifications");
    });
}
export async function updateStartNotification(start) {
  return firebase
    .database()
    .ref("notifications")
    .child("start")
    .set(start)
    .then(resp => {
      //showToast
    })
    .catch(e => {
      console.log("there was a problem updating notifications");
    });
}
export async function updateResultNotification(result) {
  return firebase
    .database()
    .ref("notifications")
    .child("result")
    .set(result)
    .then(resp => {
      //showToast
    })
    .catch(e => {
      console.log("there was a problem updating notifications");
    });
}
export async function updateReminderNotification(reminder) {
  return firebase
    .database()
    .ref("notifications")
    .child("reminder")
    .set(reminder)
    .then(resp => {
      //showToast
    })
    .catch(e => {
      console.log("there was a problem updating notifications");
    });
}

export async function getUsers(from, limit) {
  let userRef = firebase
    .firestore()
    .collection("users")
    .orderBy("name", "asc");

  if (from) {
    userRef = userRef.startAfter(from);
  }
  let snapshot = await userRef.limit(limit).get();
  let users = [];
  snapshot.docs.forEach(snapDoc => {
    if (snapDoc.data()) {
      users.push(snapDoc.data());
    }
  });
  return users;
}

export async function getUserDetail(uid) {
  let userInfo = {};
  let userRef = firebase
    .firestore()
    .collection("users")
    .doc(uid);
  try {
    let userFields = await userRef.get();
    userInfo.user = userFields.data();
  } catch (err) {
    console.log("there was an error fetching user detail");
    userInfo.user = {};
  }
  try {
    let userVotes = await userRef
      .collection("votes")
      .orderBy("date", "desc")
      .get();
    let votes = [];
    userVotes.docs.map(vote => {
      votes.push(vote.data());
    });
    userInfo.votes = votes;
  } catch (e) {
    console.log("there was an error fetching votes");
    userInfo.votes = [];
  }
  return userInfo;
}

export async function getWeekInfo(weekKey) {
  let docSnap = await firebase
    .firestore()
    .collection("weeks")
    .doc(weekKey)
    .get();
  return docSnap.data();
}

export async function getPDOUsers(weeks = 12, minVotes = 5) {
  const calculatePDO = firebase.functions().httpsCallable("calculatePDO");
  const pdoUsers = await calculatePDO({ weeks, minVotes });
  return pdoUsers;
}
