//for easycron
const apiKey = "1fbde4d175bea1efd627269303c2285e";
const method = "edit";
const startUrl =
  "https://us-central1-consensusfx-23e72.cloudfunctions.net/startNewVotingWindow";
const endUrl =
  "https://us-central1-consensusfx-23e72.cloudfunctions.net/endVotingWindow";
const resultUrl =
  "https://us-central1-consensusfx-23e72.cloudfunctions.net/calculateResult";
const remindUrl =
  "https://us-central1-consensusfx-23e72.cloudfunctions.net/notifyNotVotedUser";

export async function updateStartCron(params) {
  //check if already exists if so then update else create
  let exp = calculateCronExpression(
    params.startTime.getHours(),
    params.startDay,
    params.startTime.getMinutes()
  );
  let name = "start_voting";
  let id = "1802775";

  let apiUrl = `https://www.easycron.com/rest/${method}?token=${apiKey}&id=${id}&url=${startUrl}&cron_expression=${exp}&cron_job_name=${name}`;
  try {
    await fetch(apiUrl);
    console.log("start", apiUrl);
  } catch (e) {
    console.log(e);
  }
  //for end Voting cron
  id = "1802776";
  let endDay = params.endDay;
  let endHour = params.endTime.getHours();
  let endMinutes = params.endTime.getMinutes();
  name = "end_voting";
  exp = calculateCronExpression(endHour, endDay, endMinutes);
  apiUrl = `https://www.easycron.com/rest/${method}?token=${apiKey}&id=${id}&url=${endUrl}&cron_expression=${exp}&cron_job_name=${name}`;
  try {
    await fetch(apiUrl);
    console.log("end", apiUrl);
  } catch (e) {
    console.log(e);
  }

  //for result cron
  id = "1802777";
  name = "calculate_results";
  let resultDay = params.resultDay;
  let resultHour = params.resultTime.getHours();

  exp = calculateCronExpression(
    resultHour,
    resultDay,
    params.resultTime.getMinutes()
  );
  apiUrl = `https://www.easycron.com/rest/${method}?token=${apiKey}&id=${id}&url=${resultUrl}&cron_expression=${exp}&cron_job_name=${name}&id=${id}`;
  try {
    await fetch(apiUrl);
    console.log("result", apiUrl);
  } catch (e) {
    console.log(e);
  }

  //for user reminding
  id = "1808748";
  name = "remind_user_to_vote";
  let remindDay = params.remindDay;
  let remindHour = params.remindTime.getHours();
  let remindMinutes = params.remindTime.getMinutes();

  exp = calculateCronExpression(remindHour, remindDay, remindMinutes);
  apiUrl = `https://www.easycron.com/rest/${method}?token=${apiKey}&id=${id}&url=${remindUrl}&cron_expression=${exp}&cron_job_name=${name}`;
  try {
    await fetch(apiUrl);
    console.log("reminder", apiUrl);
  } catch (e) {
    console.log(e);
  }
}
function calculateCronExpression(startTimeHour, week, minute = 0) {
  return `${minute} ${startTimeHour} * * ${week}`;
}
