import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { getPDOUsers } from "../controllers/firebase";
const PDO = props => {
  let [weeks, setWeeks] = useState(12);
  let [pdoUsers, setPdoUsers] = useState({ data: [] });
  let [loading, setLoading] = useState(false);
  const weeksChange = e => {
    setWeeks(parseInt(e.target.value));
  };
  const generatePdo = () => {
    setLoading(true);
    getPDOUsers(weeks, weeks === 12 ? 5 : 10).then(pdoUsers => {
      setPdoUsers(pdoUsers);
      setLoading(false);
    });
  };

  useEffect(() => {
    generatePdo();
  }, []);

  const renderPDOUsers = () => {
    if (pdoUsers.data.length < 1) {
      return (
        <div
          style={{
            display: "flex",
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "240px"
          }}
        >
          <p>No PDO Users</p>
        </div>
      );
    }
    return pdoUsers.data.map(pdoUser => {
      return (
        <Card
          key={pdoUser.uid}
          style={{ margin: 10, cursor: "pointer" }}
          onClick={() => props.history.push(`/dashboard/users/${pdoUser.uid}`)}
        >
          <CardContent style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: 130
              }}
            >
              <Avatar src={pdoUser.image} />
              <p>{pdoUser.name}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10
              }}
            >
              <p>{`Vote count: ${pdoUser.voteCount}`}</p>
              {`Accuracy: ${pdoUser.accuracy}`}
            </div>
          </CardContent>
        </Card>
      );
    });
  };

  const renderSpinner = () => {
    return (
      <div
        style={{
          display: "flex",
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "240px"
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <Select value={weeks} onChange={weeksChange}>
          <MenuItem value={12}>PDO 1 (12 weeks)</MenuItem>
          <MenuItem value={30}>PDO 2 (30 weeks)</MenuItem>
        </Select>
        <Button
          variant="outlined"
          onClick={generatePdo}
          style={{ marginLeft: 10 }}
        >
          Generate PDO
        </Button>
      </div>
      {!loading ? renderPDOUsers() : renderSpinner()}
    </div>
  );
};

export default PDO;
