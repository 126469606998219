import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import User from "./Users";
import UserDetail from "./UserDetail";

const UserRoutes = props => {
  return (
    <Switch>
      <Route
        path="/dashboard/users/:uid"
        component={UserDetail}
        {...props}
        exact
      />
      <Route path="/dashboard/users" component={User} {...props} exact />
      <Redirect to="/dashboard/users" />
    </Switch>
  );
};

export default UserRoutes;
