import styled from "styled-components";
import React, { useState } from "react";

import { loginWithEmailPassword } from "../controllers/firebase";
import { CircularProgress } from "@material-ui/core";
import { ValidateEmail } from "../helpers";
import { StyledForm } from "../styles/styled";

import {
  TextField,
  Card,
  Button,
  Typography,
  FormControl
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { CenterScreen } from "../styles/styled";

const Login = props => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: {
      value: "",
      error: false
    },
    password: {
      value: "",
      error: false
    },
    error: null
  });

  const attemptLogin = e => {
    if (ValidateEmail(form.email.value) && form.password.value !== "") {
      setLoading(true);
      loginWithEmailPassword(form.email.value, form.password.value).catch(
        err => {
          window.alert(err.message);
          setLoading(false);
        }
      );
    }
    setForm({ ...form, error: "Please check errors" });
  };

  const handleInput = event => {
    let error = false;
    if (event.target.name === "email") {
      error = !ValidateEmail(event.target.value);
    }
    setForm({
      ...form,
      [event.target.name]: { value: event.target.value, error: error }
    });
  };

  return (
    <CenterScreen>
      <LoginWrapper {...props}>
        <LoginBox {...props}>
          <SubText>Consensus</SubText>
          <Logo>FX</Logo>
        </LoginBox>
        <LoginForm {...props}>
          <Typography variant="h4" color="primary">
            Log In
          </Typography>
          <StyledForm>
            <TextField
              fullWidth
              error={form.email.error}
              required
              label="Email"
              id="email"
              type="email"
              name="email"
              onChange={handleInput}
            />
            <TextField
              error={form.password.error}
              required
              fullWidth
              label="Password"
              id="password"
              type="password"
              name="password"
              onChange={handleInput}
            />
            <div>
              {!loading && (
                <StyledButton
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={attemptLogin}
                >
                  Log In
                </StyledButton>
              )}
              {loading && <CircularProgress size={20} />}
            </div>
          </StyledForm>
        </LoginForm>
      </LoginWrapper>
    </CenterScreen>
  );
};

export default withTheme()(Login);

const LoginWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 60%;
  @media (max-width: 1024px) {
    flex-direction: column;
    & > div:nth-child(2) {
      flex-grow: 20;
    }
  }
  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
  && {
    -webkit-box-shadow: 18px -7px 143px -19px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 18px -7px 143px -19px rgba(0, 0, 0, 0.43);
    box-shadow: 18px -7px 143px -19px rgba(0, 0, 0, 0.43);
  }
`;

const LoginBox = styled.div`
  background-color: ${props => props.theme.palette.primary.main};
  display: flex;
  flex: 1.5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1024px) {
    flex-grow: 0;
    padding: 10px;
  }
`;

const LoginForm = styled.div`
  color: ${props => props.theme.palette.primary.main};
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 30px;
  flex-direction: column;
`;

const Logo = styled.span.attrs({
  className: "test"
})`
  font-size: 8vw;
  font-weight: bold;
  color: white;
`;
const SubText = styled.span`
  font-size: 3vw;
  font-weight: bold;
  color: white;
`;

const StyledButton = styled(Button)`
  && {
    margin-top: 10px;
  }
`;
